// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$facebook-logo-name: 'facebook-logo';
$facebook-logo-x: 30px;
$facebook-logo-y: 0px;
$facebook-logo-offset-x: -30px;
$facebook-logo-offset-y: 0px;
$facebook-logo-width: 32px;
$facebook-logo-height: 32px;
$facebook-logo-total-width: 110px;
$facebook-logo-total-height: 94px;
$facebook-logo-image: '/images/sprite_all.png';
$facebook-logo: (30px, 0px, -30px, 0px, 32px, 32px, 110px, 94px, '/images/sprite_all.png', 'facebook-logo', );
$instagram-logo-name: 'instagram-logo';
$instagram-logo-x: 64px;
$instagram-logo-y: 0px;
$instagram-logo-offset-x: -64px;
$instagram-logo-offset-y: 0px;
$instagram-logo-width: 32px;
$instagram-logo-height: 32px;
$instagram-logo-total-width: 110px;
$instagram-logo-total-height: 94px;
$instagram-logo-image: '/images/sprite_all.png';
$instagram-logo: (64px, 0px, -64px, 0px, 32px, 32px, 110px, 94px, '/images/sprite_all.png', 'instagram-logo', );
$mouse-name: 'mouse';
$mouse-x: 0px;
$mouse-y: 0px;
$mouse-offset-x: 0px;
$mouse-offset-y: 0px;
$mouse-width: 28px;
$mouse-height: 60px;
$mouse-total-width: 110px;
$mouse-total-height: 94px;
$mouse-image: '/images/sprite_all.png';
$mouse: (0px, 0px, 0px, 0px, 28px, 60px, 110px, 94px, '/images/sprite_all.png', 'mouse', );
$sprite-bk-name: 'sprite_bk';
$sprite-bk-x: 98px;
$sprite-bk-y: 0px;
$sprite-bk-offset-x: -98px;
$sprite-bk-offset-y: 0px;
$sprite-bk-width: 12px;
$sprite-bk-height: 53px;
$sprite-bk-total-width: 110px;
$sprite-bk-total-height: 94px;
$sprite-bk-image: '/images/sprite_all.png';
$sprite-bk: (98px, 0px, -98px, 0px, 12px, 53px, 110px, 94px, '/images/sprite_all.png', 'sprite_bk', );
$vk-social-name: 'vk_social';
$vk-social-x: 0px;
$vk-social-y: 62px;
$vk-social-offset-x: 0px;
$vk-social-offset-y: -62px;
$vk-social-width: 32px;
$vk-social-height: 32px;
$vk-social-total-width: 110px;
$vk-social-total-height: 94px;
$vk-social-image: '/images/sprite_all.png';
$vk-social: (0px, 62px, 0px, -62px, 32px, 32px, 110px, 94px, '/images/sprite_all.png', 'vk_social', );
$spritesheet-width: 110px;
$spritesheet-height: 94px;
$spritesheet-image: '/images/sprite_all.png';
$spritesheet-sprites: ($facebook-logo, $instagram-logo, $mouse, $sprite-bk, $vk-social, );
$spritesheet: (110px, 94px, '/images/sprite_all.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
