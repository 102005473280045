// /src/scss/vendor/_swiper.scss
// настройка слайдера swiper http://idangero.us/swiper/api/

// слайдер на главной странице
.review-slider {
  position: relative;

  &__container {
    max-width: calc(100% - #{$gutter} * 2 - 30px * 2); // 30 - размер стрелок
    margin-right: auto;
    margin-left: auto;
    padding: 0;
    @media (min-width: $breakpoint) {
      max-width: 1100px;
    }
  }
  &__title {
    display: flex;
    justify-content: center;
    @media (min-width: $lg) {
      justify-content: flex-end;
    }
    h2 {
      text-align: center;
      @media (min-width: $sm) {
        margin-bottom: $gutter;
      }
      @media (min-width: $lg) {
        margin-bottom: 0;
        text-align: left;
      }
      @media (min-width: $lg) and (max-width: $breakpoint - 1) {
        width: calc(100% - 50px - 425px);
      }
      @media (min-width: $breakpoint) {
        width: calc(100% - 127px - 425px);
      }
    }
  }
  &__slide {
    display: flex;
    flex-direction: column;
    max-width: 100%;

    @media (min-width: $lg) {
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  }
  &__img {
    display: none;
    overflow: hidden;
    width: 230px;
    height: 230px;
    margin: 0 auto;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    @media (min-width: $md - 1) {
      display: flex;
    }
    @media (min-width: $lg) {
      width: 425px;
      min-width: 425px;
      height: 425px;
    }
    @media (min-width: $lg) and (max-width: $breakpoint - 1) {
      margin: 0 50px 0 0;
    }
    @media (min-width: $breakpoint) {
      margin: 0 127px 0 0;
    }
    img {
      @extend .img;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    @include media(phone){
      overflow: hidden;
    }
    @media (min-width: $md - 1) {
      padding: 0 $gutter;
    }
    @media (min-width: $lg) and (max-width: $breakpoint - 1) {
      width: calc(100% - 50px - 425px);
    }
    @media (min-width: $breakpoint) {
      width: calc(100% - 127px - 425px);
    }
    h3 {
      margin: 10px 0;
      color: #06a5cd;
      @include font(1.8rem, 400, 2rem, OfficinaSansExtraBoldC);
      @include media(phone){
        max-width:36rem;
      }
      @media (min-width: $md - 1) {
        margin: 17px 0;
        @include font(3rem, 400, 3.6rem, OfficinaSansExtraBoldC);
      }
    }
  }
  &__about {
    margin-bottom: 10px;
    color: #333;
    @include font(16px, 400, 22px, OfficinaSansBookC);
    @media (min-width: $md - 1) {
      margin-bottom: 36px;
    }
  }
  &__text {
    position: relative;
    overflow: hidden;
    max-height: 324px;

    @media (min-width: $sm) {
      overflow: auto;
      max-height: inherit;
      padding: $gutter;
      border-radius: 5px;
      background-color: white;
      box-shadow: 2.9px 4px 0 1px rgba(0, 0, 0, .11);
    }
    @media (min-width: $md) {
      /*padding: 35px;*/
    }
    &:before {
      position: absolute;
      top: 22px;
      left: 0;
      width: 0;
      height: 0;
      transform: translateX(-100%);
      border-width: 11px 15px 11px 0;
      border-style: solid;
      border-color: transparent white transparent transparent;
      @media (min-width: $sm) {
        content: '';
      }
    }
    p {
      @include font(1.6rem, 400, 1.8rem, OfficinaSansMediumC);
      @media (min-width: $sm) {
        @include font(1.8rem, 400, 2.3rem, OfficinaSansMediumC);
      }
    }
  }
  // точки
  &__pagination {
    position: static;

    display: none;
    width: 425px !important;
    padding: 5px;
    text-align: center;
    //border: 1px solid #000;
    @media (min-width: $lg) {
      display: block;
    }
    & .swiper-pagination-bullet {
      width: 16px;
      height: 16px;

      opacity: 1;
      background: white;

      &:not(:last-child) {
        margin-right: 2.5rem;
      }
    }
    & .swiper-pagination-bullet-active {
      background: $blue;
      box-shadow: 0 0 0 5px white;
    }
  }
  &__arrows-wrapper {
    position: absolute;
    z-index: 9999;
    top: 60%;
    left: 50%;
    display: flex;
    width: 100%;
    max-width: $site-width;
    height: 54px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 $gutter;
    transform: translate(-50%, -50%);

    justify-content: space-between;
    @media (min-width: $sm) {
      top: 50%;
    }
  }
  // стрелки слайдера
  &__prev,
  &__next {
    position: relative;
    top: 0;
    width: 30px;
    height: 54px;
    margin: 0;
    background: transparent;

    &:before {
      position: absolute;
      top: 50%;
      display: block;
      width: 16px;
      height: 16px;
      content: '';
      border-bottom: 4px solid white;
      @media (min-width: $sm) {
        width: 38px;
        height: 38px;
      }
    }
  }
  &__prev {
    left: 0;
    &:before {
      left: 0;
      transform: translate(8px, -50%) rotate(45deg);
      border-left: 4px solid white;
    }
    &:hover:before {
      border-bottom: 4px solid $blue;
      border-left: 4px solid $blue;
    }
  }
  &__next {
    right: 0;
    &:before {
      right: 0;
      transform: translate(-8px, -50%) rotate(-45deg);
      border-right: 4px solid white;
    }
    &:hover:before {
      border-right: 4px solid $blue;
      border-bottom: 4px solid $blue;
    }
  }
}
