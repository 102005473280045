// /src/scss/pages/_main.scss

// generic transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

$elements: 9;
$menu1: 70;
$grad: 22.5;
$img_grad: 242.5;
$content_grad: -92.5;
@for $i from 1 to $elements {
  @if $content_grad >= 360 {
    $content_grad: $content_grad - 360
  }
  @if $content_grad <= -360 {
    $content_grad: $content_grad + 360
  }

  div.item#{$i} {
    @media (min-width: $md) {
      z-index: $i;
      @include rotate($grad);
      .content {
        @include rotate($content_grad);
      }
    }
  }

  $content_grad: ($content_grad - 22.5);
  //@debug $content_grad;
  //@debug $i;
  $grad: $grad+22.5;
}

.first-page {
  position: relative;

  &__wrapper {
    @include media(phone) {
      max-width: $site-width - ($gutter * 2);
      padding: 0 ($gutter + 1.5rem + $gutter);
    }

    #menu1 {
      position: absolute;
      @include media(phone) {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        top: 20vh;
      }

      @media (min-width: $md) {
        top: 0; //40px
        left: 300px;
        height: 350px; //500px;
        width: 350px; //500px;
        @include rotate($menu1);
      }
      @media screen and (min-width: $md) and (max-width: $lg) {
        top: 150px;
      }

      @media (min-width: $lg) {
        left: 400px;
      }
      /*@media (min-width: $xl) and (min-height: 800px) {
        left: 500px;
        height: 450px;
        width: 450px;
      }*/
    }

    .item {
      max-width: 9rem;
      height: 9rem;
      @media (orientation: landscape) and (max-width: $md - 1) {
        margin-right: 10px;
        margin-bottom: 10px;
        max-width: 10rem;
        height: 10rem;
      }
      @media (max-width: $md - 1) {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 9rem;
        -ms-flex: 0 0 9rem;
        flex: 0 0 9rem;
        &.item1{
          background: transparentize($green, .3);
        }
        &.item2{
          background: transparentize($blue, .3);
        }
        &.item3{
          background: transparentize($tomato, .3);
        }
        &.item4{
          background: transparentize($pink, .3);
        }
        &.item5{
          background: transparentize($pear-green, .3);
        }
        &.item6{
          background: transparentize($persian-green, .3);
        }
        &.item7{
          background: transparentize($orange, .3);
        }
        &.item8{
          background: transparentize($purple, .3);
        }
      }
      @media (min-width: $md) {
        width: 70%; //290px;
        max-width: 100%;
        height: 100%; //440px;
        -webkit-transform-origin: 100% 100%;
        -moz-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        position: absolute;
        @media (max-height: 600px) {
          width: 65%;
          height: 80%;
        }
        /*&.item3 > div:hover .content {
          left: 25%;
        }*/
        &>div {
            position: absolute;
        }
      }
      &>div {
        &:hover .content {
          font-size: 16px;
          img{
            height: 90%;
            width: 90%;
          }
        }
        p{
          color: #fff;
          font-size: 16px;
          width: 90px;
          word-wrap: break-word;
          line-height: 1;
          text-align: center;
          @include media(phone) {
            margin: 0 -1rem;
          }
        }
        a{
          color: #fff;
          text-decoration: none;
          & > img {
            @include media(phone) {
              display: none !important;
            }
            @media (min-width: $md) {
              /*min-width: 230px;
              min-height: 173px;*/
              min-width: 200px;
              min-height: 140px;
              width: 100%;
              height: 100%;
              position: absolute;
              @include rotate($img_grad);
            }
            /*@media (min-width: $md) {
              min-width: 200px;
              min-height: 140px;
            }*/
            /*@media (min-width: $xl) {
              min-width: 230px;
              min-height: 173px;
            }*/
          }
        }

      }
      .content {
        position: relative;
        text-align: center;
        @include media(phone) {
          padding: 1rem;
          span{
            height: 60px;
            width: 80px;
            justify-content: center;
            align-items: center;
            display: flex;
          }
        }
        @media (min-width: $md) {
          top: -5px;
          left: 35px; //45px
          width: 100%;
          height: 100%;
          font-size: 16px;
        }
        @media (min-width: $lg) {
          top: 0;
          left: 40px // 55px;
        }
        /*@media (min-width: $xl) {
          top: 0;
          left: 55px;
        }*/
        img{
          @include media(phone) {
            max-width: 60px;
            max-height: 50px;
          }
          @media (min-width: $md) {
            display: block;
            margin: 0 auto;
            height: 80%;
            width: 80%;
            max-height: 90px;
            max-width: 90px;
          }
          /*@media (min-width: $md) {
            min-width: 60px;
            min-height: 60px;
          }*/
        }

      }
    }

    #center{
      -webkit-flex: 0 0 9rem;
      -ms-flex: 0 0 9rem;
      flex: 0 0 9rem;
      max-width: 9rem;
      height: 9rem;
      @media (orientation: landscape) and (max-width: $md - 1) {
        margin-right: 10px;
        margin-bottom: 10px;
        -webkit-flex: 0 0 10rem;
        -ms-flex: 0 0 10rem;
        flex: 0 0 10rem;
        max-width: 10rem;
        height: 10rem;
      }
      @include media(phone) {
        background: #fff;
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1;
        -webkit-box-flex: 0;
      }
      @media (min-width: $md) {
        position: absolute;
        z-index: 9;
        top: 250px;
        left:100px;
        height: 10rem;
        width: 100%;
        max-width: 90%;
        @media (max-height: 600px) {
          top: 160px;
          left: 80px;
          width: 80%;
        }
      }
      /*@media (min-width: $xl) {
        top: 350px;
      }*/
      a{
        padding: 16px 10px;
        display: block;
        @media (min-width: $sm) and (max-width: $md - 1) {
          padding: 18px 10px;
        }
        @media (min-width: $md) {
          @include rotate(290);
          &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: -webkit-radial-gradient(rgba(255,255,255,1) 0%, rgba(255,255,255,0) 80%); /* Safari 5.1- 6.0 */
            background: -o-radial-gradient(rgba(255,255,255,1) 0%, rgba(255,255,255,0) 80%); /* Opera 11.6-12.0 */
            background: -moz-radial-gradient(rgba(255,255,255,1) 0%, rgba(255,255,255,0) 80%); /* Firefox 3.6-15 */
            background: radial-gradient(rgba(255,255,255,1) 0%, rgba(255,255,255,0) 80%);
          }
        }
        img{
          position: relative;
          margin-bottom: 0;
          @include media(phone) {
            max-width: 100%;
          }
          /*@media (min-width: $md - 1) {
            width: 406px;
            height: 323px;
          }*/
          @media (min-width: $md) {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

  }
  &__container {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    display: flex;
    overflow: visible;
    flex-direction: column;
    padding-top: 3rem;
    transform: translate(-50%, -50%);
    text-align: center;
    align-items: center;
    @media (min-width: $sm) {
      top: 0;
      min-width: 61rem;
      padding-top: 10.5rem;
      transform: translate(-50%, 0);
    }
  }
  &__logo,
  h1,
  p {
    z-index: 12;
  }
  &__logo {
    margin-bottom: 1.5rem;
    @media (min-width: $sm) {
      margin-bottom: 6rem;
    }
  }
  h1 {
    margin-bottom: 1.5rem;
    color: #222;

    @include font(3rem, 400, 3.2rem, OfficinaSansExtraBoldOSC);
    @media (min-width: $sm) {
      margin-bottom: 1.5rem;

      @include font(4.8rem, 400, 5.6rem, OfficinaSansExtraBoldOSC);
    }
  }
  /*p {
    color: #222;

    @include font(2rem, 400, 2.2rem, OfficinaSansBookC);
    @media (min-width: $sm) {
      @include font(2.2rem, 400, 3.4rem, OfficinaSansBookC);
    }
  }*/
  .brighten {
    top: 50%;
    left: 50%;
    width: 75%;
    height: 75%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  .video_btnPlay{
    position: absolute;
    bottom: 8rem;
    left: 0;
    background: rgba(13, 60, 75, 0.5);
    padding: 0.7rem;
    @media (min-width: $sm) {
      left: 8rem;
    }
    .btnPlay {
      color: #fff;
      margin-top: 0;
      i{
        color: #fff;
        font-size: 38px;
        position: relative;
      }
      svg{
        height: 40px;
        vertical-align: middle;
        display: inline-block;
      }
      span {
        vertical-align: middle;
        display: inline-block;
        width: 130px;
        color: #fff;
        line-height: 1.2;
        .text-hight{
          text-transform: uppercase;
        }
      }
    }
  }

  .translation {
    position: absolute;
    top: 2rem;
    right: 2rem;
    a{
      font-size: 20px;
      color: #fff;
      margin-right: 5px;
      text-decoration: none;
      &.active, &:hover{
        text-decoration: underline;
      }
    }
  }
  .mouse {
    @include media(phone) {
      display: none;
    }
    position: absolute;
    bottom: 5rem;
    right: 2rem;
    display: flex;
    width: 25rem;
    height: 4.7rem;
    align-items: center;
    justify-content: flex-end;
    p {
      padding-right: 2rem;
      color: #fff;
      text-transform: uppercase;
      text-align: right;
      @include font(1.8rem, 400, 2rem, OfficinaSansBookC);
    }
    .icon-mouse-wireless{
      background: url('/images/mouse.png') no-repeat;
      color: #fff;
      transform: translateY(0);
      animation-duration: 5s;
      animation-timing-function: linear;
      animation-delay: 1s;
      animation-iteration-count: infinite;
      will-change: transform;
      animation-name: arrow;
      height: 6rem;
      width: 7rem;
    }

    .icon-arrow {
      color: #fff;
      transform: translateY(0);
      animation-duration: 5s;
      animation-timing-function: linear;
      animation-delay: 1s;
      animation-iteration-count: infinite;
      will-change: transform;
      animation-name: arrow;
    }
  }
}

.panorama {
  width: 100%;
  height: 100vh;
  @media (max-width: 568px) {
    background: transparent url(/images/sputnik-sm.jpg) no-repeat center;
  }
  &__img {
    display: none;

    height: 100vh;

    transform: translateX( -37% );
    animation-duration: 150s;
    animation-timing-function: linear;
    animation-delay: 1s;
    animation-iteration-count: infinite;

    will-change: transform;
    @media (min-width: 569px) {
      display: inline;
      animation-name: swing;
    }
    @media (min-width: $md) and (max-width: 1365px) {
      transform: translateX( -3100px );
    }
    @media (min-width: 1366px) {
      transform: translateX( -2400px );
    }
  }
}

@keyframes swing {
  25% {
    transform: translateX(calc(-100% + 100vw));
  }
  75% {
    transform: translateX(0);
  }
}

@media (min-width: 320px) and (max-width: $md - 1) {
  @keyframes swing {
    100% {
      transform: translateX(-1750px);
    }
  }
}

@media (min-width: $md) and (max-width: 1365px) {
  @keyframes swing {
    100% {
      transform: translateX(-3100px);
    }
  }
}

@media (min-width: 1366px) {
  @keyframes swing {
    100% {
      transform: translateX(-2400px);
    }
  }
}

@keyframes arrow {
  25% {
    transform: translateY(-2px);
  }
  75% {
    transform: translateY(10px);
  }
}