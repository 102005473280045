// /src/scss/_vars.scss

$site-width: 136.6rem;
$font-family: OfficinaSansBookC, Arial, sans-serif;  // Передаётся в _reset.scss
$font: OfficinaSansBookC;                      // Шрифт, используется в миксинах
$black: #0f1116;
$brand-color: #8cd1f9;

$breakpoint: 1200px;

$gutter: 1.4rem;

// Брендовые цвета
$green:         #53c11d;  // Здоровый город
$pear-green:    #bbd216;  // Доступный город
$persian-green: #00b29b;  // Деловой город
$blue:          #07a1e7;  // Безопасный город
$light-blue:    #3db4d8;
$tomato:        #f85c42;  // Самостоятельный город
$orange:        #fca51b;  // Семейный город
$pink:          #f03cb7;  // Активный город
$purple:        #793bc5;  // Город для души

$border: #e5e8ec;         // цвет бордера таблицы

$sm:  544px;              // Small screen / phone
$md:  768px;              // Medium screen / tablet
$lg:  992px;              // Large screen / desktop
$xl:  1200px;             // Extra large screen desktop
$xxl: 1440px;             // Wide desktop
