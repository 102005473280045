// /src/scss/vendor/_fullpage.scss
// настройка слайдера swiper https://github.com/alvarotrigo/fullPage.js

.fp-slidesNav.top {
  top: 10rem;
  left: 50%;

  display: none;

  width: 100%;
  max-width: $site-width - ($gutter * 2);
  margin: 0 !important;
  padding: 0 ($gutter + 1.5rem + $gutter);

  transform: translateX(-50%);
  // возможные значения - phone, tablet, laptop, desktop, wide. см. _mixins.scss

  @include media(tablet) {
    top: 18rem;

    display: flex;

    padding: 0 ($gutter + 1.5rem + $gutter);                  // 1.5rem - ширина стрелки
  }
  justify-content: flex-end;
  @media (min-width: $lg) {
    top: 19.5rem;

    display: flex;

    max-width: $xxl;
    padding: 0 ($gutter + 3rem + $gutter);                  // 3rem - ширина стрелки
  }
  ul {
    width: 100%;
    @media (min-width: $lg) {
      width: 55%;
    }
  }
}
.fp-slidesNav {
  ul {
    display: flex;

    flex-wrap: wrap;
    justify-content: flex-end;
  }
  ul li {
    display: flex;

    width: auto;
    height: auto;
    margin: 0;
  }
  ul li a {
    display: flex;

    min-height: 4.2rem;
    padding: 0.5rem 2rem;

    border-radius: 0.9rem !important;

    align-items: center;
    justify-content: center;
  }
  ul li a span,
  ul li a.active span {
    position: static;

    display: inline;

    width: auto !important;
    height: auto !important;
    margin: 0 !important;

    border-radius: 0;
    background: none;

    @include font(1.5rem, 400, 1.5rem, OfficinaSansExtraBoldC);
  }
  ul li a span {
    color: $brand-color;
    @media (min-width: $md - 1) {
      border-bottom: 1px dashed $brand-color;
    }
  }
  ul li:hover a span {
    @media (min-width: $md - 1) {
      border-bottom: 1px dashed transparent;
    }
  }
  ul li a.active {
    background-color: $brand-color;
  }
  ul li a.active span {
    color: white;
    border: none;
  }
}

.fp-controlArrow {
  top: calc(100vh - #{$slide-footer-small-height}/2 );

  opacity: 0.41;
  border: none;

  @include media(tablet) {
    top: calc(100vh - #{$slide-footer-tablet-height}/2 );
  }
  @media (min-width: $lg) {
    top: calc(100vh - #{$slide-footer-widescreen-height}/2 );
  }
  &:hover {
    opacity: 1;
  }
  &:before {
    position: absolute;
    display: block;
    width: 1rem;
    height: 1rem;
    content: '';
    border: 0.2rem solid rgba(red, 0.7);
    // возможные значения - phone, tablet, laptop, desktop, wide. см. _mixins.scss
    @include media(tablet) {
      width: 2rem;
      height: 2rem;
      border-width: 0.3rem;
    }
    @media (min-width: $lg) {
      width: 4rem;
      height: 4rem;
      border-width: 0.7rem;
    }
  }
  &:hover:before {
    border-color: red;
  }
  &.fp-prev {
    left: $gutter;
    @include media(phone) {
      left: $gutter*2;
    }
    @media (min-width: $md) and (orientation: landscape) {
      left: $gutter*3;
    }
    @media (min-width: $xxl) {
      left: calc( (100vw - #{$xxl}) / 2  + #{$gutter});
    }
    &:before {
      transform: translate(0, 1rem) rotate(45deg);
      transform-origin: left bottom;
      border-top: none;
      border-right: none;
    }
  }
  &.fp-next {
    right: $gutter;
    @include media(phone) {
      right: $gutter*2;
    }
    @media (min-width: $md) and (orientation: landscape) {
      right: $gutter*3;
    }
    @media (min-width: $xxl) {
      right: calc( (100vw - #{$xxl}) / 2  + #{$gutter});
    }
    &:before {
      transform: translate(-100%, 1rem) rotate(-45deg);
      transform-origin: right bottom;

      border-top: none;
      border-left: none;
    }
  }
}

// навигация слева
#fp-nav.left {
  top: 4.3rem;

  margin: 0 !important;

  @include media(tablet) {
    top: 20rem;
  }
  @media (min-width: $md - 1) {
    top: 22.5rem;
  }
}

#fp-nav {
  ul li {
    width: 0.7rem;
    height: 0.7rem;
    margin: 0;
    margin-bottom: 1.5rem;

    @include media(tablet) {
      width: 1.6rem;
      height: 1.6rem;
      margin-bottom: 2.7rem;
    }
    @media (min-width: $md) {
      width: 1.6rem;
      height: 1.6rem;
      margin-bottom: 2.7rem;
    }
  }
  ul li a {
    display: flex;

    align-items: center;
    justify-content: center;
  }
  ul li a.active span {
    box-shadow: 0 0 0 5px white;
  }
  ul li a span {
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    box-shadow: 0 0 0 1px #ccc;
    background-color: white;
  }
  // цвет активного круга
  a.active span {
    background-color: $brand-color;
  }
  @each $name, $color in  (green, $green),
                          (pear-green, $pear-green),
                          (persian-green, $persian-green),
                          (blue, $blue),
                          (light-blue, $light-blue),
                          (tomato, $tomato),
                          (orange, $orange),
                          (pink, $pink),
                          (purple, $purple) {
    a.active.#{$name} span {
      background-color: $color;
    }
  }
  ul li .fp-tooltip.left {
    font: 400 1.4rem/4.2rem OfficinaSansMediumC;

    top: 50%;
    left: calc(100% + 1.5rem + 0.5rem);

    overflow: visible;

    max-width: none;
    padding: 0 2.5rem;

    transform: translateY(-50%);

    color: black;
    border-radius: 0.5rem;
    background-color: white;
    box-shadow: 3px 4px 0 1px rgba(black, 0.11);
    @media (max-width: $md - 1) {
      display: none;
    }
    &:before {
      position: absolute;
      z-index: 1000;
      top: 50%;
      left: 0;

      width: 0;
      height: 0;

      content: '';
      transform: translate(-100%, -50%);

      border-width: 7px 9px 7px 0;
      border-style: solid;
      border-color: transparent white transparent transparent;
    }
    @each $name, $color in  (green, $green),
                            (pear-green, $pear-green),
                            (persian-green, $persian-green),
                            (blue, $blue),
                            (light-blue, $light-blue),
                            (tomato, $tomato),
                            (orange, $orange),
                            (pink, $pink),
                            (purple, $purple) {
      &.#{$name} {
        color: white;
        background-color: $color;
        &:before {
          border-color: transparent $color transparent transparent;
        }
      }
    }
  }
}