// /src/scss/_common.scss
// Общие стили для всех страниц

// Не адаптивное одноуровневое навигационное меню меню
//.text-decoration{}
.nav {
  z-index: 9999;
  &__list {
    display: flex;
    list-style: none;
    justify-content: space-between;
  }
  &__list-item {
    position: relative;
    &.active,
    &:hover {
      background-color: rgba(white, .8);
      & .nav__link {
        color: #016dbb;
      }
    }
  }
  &__link {
    display: inline-block;
    padding: 0 2rem;
    color: white;
    @extend .text-decoration !optional;
    @include font(1.3rem, 700, 6.9rem);
  }
  &__sub-list {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    min-width: 100%;
    list-style: none;
    background-color: white;
    box-shadow: 0 4px 5px rgba(0, 0, 0, .13);
  }
  &__list-item:hover .nav__sub-list {
    display: block;
  }
  &__sub-link {
    line-height: 4.5rem;
    display: inline-block;
    padding: 0 2rem;
    white-space: nowrap;
    color: $black;
    @extend .text-decoration !optional;
    @include font(1.5rem, 700, 4rem);
    &:hover {
      color: #e37114;
    }
  }
}

.section {
  position: relative;
  padding: 0 !important;
  // стилизация секций. Название цветов и соответствие страницам см. _vars.scss
  @each $name, $color in (green, $green),
    (pear-green, $pear-green),
    (persian-green, $persian-green),
    (blue, $blue),
    (light-blue, $light-blue),
    (tomato, $tomato),
    (orange, $orange),
    (pink, $pink),
    (purple, $purple) {
    &--#{$name} {
      .fp-slidesNav ul li a.active {
        background-color: $color;
      }
      .fp-slidesNav ul li a span {
        color: $color;
        border-bottom: 1px dashed $color;
      }
      .fp-controlArrow:before {
        border-color: $color;
      }
      .fp-controlArrow:hover:before {
        border-color: $color;
      }
      .slide__round {
        border: 7px solid $color;
      }
    }
  }
}

// стилизация компонентов слайда
// Локальные переменные, введены для удобства работы с адаптивом
$slide-footer-small-height: 22rem;
$slide-footer-tablet-height: 32.5rem;
$slide-footer-widescreen-height: 26.7rem;
.slide {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include media(phone) {
    background-position-y: -5rem;
  }
  &__container {
    display: flex;
    overflow: hidden;
    width: 100%;
    max-width: $site-width - ($gutter * 2);
    height: 100%;
    padding: 0 ($gutter + 1.5rem + $gutter);
    align-items: center;
    // возможные значения - phone, tablet, laptop, desktop, wide. см. _mixins.scss
    @include media(tablet) {
      padding: 0 ($gutter + 1.5rem + $gutter); // 1.5rem - ширина стрелки
    }
    justify-content: space-between;
    @media (min-width: $lg) {
      max-width: $xxl;
      padding: 0 ($gutter + 3rem + $gutter); // 3rem - ширина стрелки
    }
    &--footer {
      flex-direction: column;
      margin: 0 auto;
      align-items: center;
      justify-content: flex-start;
      @media (max-width: $md - 1) {
        align-items: center;
        justify-content: center;
      }
    }
  }
  &__header {
    display: flex;
    width: 100%;
    padding-top: 3rem;
    justify-content: center;
    @media (max-width: $sm) {
      flex-direction: column;
    }
    @media (min-width: $md) and (max-width: $xl - 1) {
      margin-bottom: 3rem;
      padding-top: 5rem;
    }
    @media (min-width: $xl) and (max-width: $xxl - 1) {
      margin-bottom: 3rem;
      padding-top: 5rem;
    }
    @media (min-width: $xxl) {
      margin-bottom: 4.5rem;
      padding-top: 9rem;
    }
    h5 {
      margin-bottom: .5rem;
      color: #06a5cd;
      @include font(1.6rem, 400, 2.4rem, OfficinaSansBoldOSC);
    }
    p,
    a {
      color: #222;
      @include font(1.6rem, 400, 2.4rem, OfficinaSansBookOSC);
    }
    a:hover {
      text-decoration: none;
    }
  }

  &__address {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    @media (min-width: $md) {
      width: span(4);
    }
  }

  /*&__address {
    margin-right: auto;
    @media (min-width: $sm) {
      order: 2;
    }
  }
  &__contacts {
    margin-left: auto;
    @media (min-width: $sm) {
      order: 3;
    }
  }*/
  &__row {
    margin-bottom: .5rem;
    @media (min-width: $md) {
      margin-bottom: 1.5rem;
    }
  }
  & &__phone {
    font-size: 2rem;
    line-height: 2rem;

    @include media(tablet) {
      font-size: 2.9rem;
      line-height: 2.9rem;
    }
    justify-content: flex-end;
    @media (min-width: $lg) {
      font-size: 3.2rem;
      line-height: 4.5rem;
    }
  }
  &__top {
    position: absolute;
    top: 0;
    left: 50%;
    display: flex;
    overflow: visible;
    padding-top: 3rem;
    transform: translateX(-50%);
    @extend .slide__container;
    @include media(tablet) {
      padding-top: 4.5rem;
    }
    justify-content: flex-end;
    @media (min-width: $lg) {
      padding-top: 10rem;
    }
    .brighten {
      top: 3rem;
      right: 0;
      width: 50%;
      height: 30rem;
      border-radius: 50%;
      &--sm {
        @media (max-width: $md - 1) {
          display: none;
        }
      }
    }
  }
  &__inner {
    display: flex;
    justify-content: flex-end;
    /*width: 50%;*/
    @include media(tablet) {
      @include font(3.6rem, 400, 4.2rem, OfficinaSansExtraBoldOSC);
    }
    @media (min-width: $lg) {
      width: 100%;
      @include font(6rem, 400, 6.2rem, OfficinaSansExtraBoldOSC);
    }
  }
  &__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: $slide-footer-small-height;
    padding: 2rem 0;
    background-color: white;
    align-items: center;
    justify-content: center;
    @media (max-width: $sm) {
      height: 30rem;
    }
    @include media(tablet) {
      height: $slide-footer-tablet-height;
      padding: 3rem 0;
    }
    @media (min-width: $lg) {
      height: $slide-footer-widescreen-height;
      padding: 3.8rem 0;
    }
  }
  &__label {
    display: none;
    @media (min-width: $lg) {
      display: flex;
      width: span(3);
      justify-content: center;
    }
  }
  &__round {
    display: flex;
    overflow: hidden;
    width: 14rem;
    height: 14rem;
    border: 7px solid $brand-color;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    & img {
      @extend .img;
    }
  }
  &__layout {
    width: span(12);
    @media (min-width: $lg) {
      width: span(9);
      padding: 0 80px 0 0; // добавил Курносов
    }
    &.user_content h3 {
      overflow: hidden;
      margin-bottom: 2.6rem;
      @include media(phone){
        margin-bottom: 1rem;
      }
    }
    &.user_content p {
      overflow: hidden;
      margin: 0;
    }
  }
  &__name {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &:after {
      font-style: italic;
      display: inline-block;
      height: 42px;
      margin-left: 3rem;
      padding: 0 15px;
      content: attr(data-attr);
      text-align: center;
      color: #222;
      border-radius: 9px;
      background-color: #ececec;
      @include font(14px, 400, 42px, OfficinaSansBookC);
    }
  }
}
.slide__top_title {
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 4;
  @media (min-width: $md) {
    right: inherit;
    left: 50%;
    transform: translateX(-50%);
    padding: 5rem 5.8rem 0;
    max-width: 1440px;
    justify-content: flex-end;
    display: flex;
    width: 100%;
  }
  @include media(tablet) {
    padding: 5rem 5.8rem;
  }
  .slide__inner{
    @media (max-width: $sm) {
      width: 90%;
    }
  }
}

.section {
  overflow: hidden;
  &--contacts{
    background: url("/images/bg-contacts.jpg") no-repeat 70% center /cover;
    /*@media (min-device-width: $md) and (orientation: portrait) {
      background: url("/images/bg-contacts.jpg") no-repeat 65% 0;
    }*/
    @media (min-width: $sm) {
      background-position: center center;
    }
  }
  &__inner {
    display: flex;
    align-items: center;
  }
  &--contacts &__inner {
    width: 100%;
    display: block;
    @include media(phone){
      flex-direction: column;
      padding: 0 5.8rem !important;
    }
    @include media(tablet){
      flex-direction: column;
      padding: 0 5.8rem;
    }
    @media (min-width: $sm) {
      width: 50%;
    }
    .section__logo {
      .btn_wontherelive{
        width: 22rem;
        font-size: 2.2rem;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        padding: 15px;
        color: rgba(34, 119, 168, .7);
        background: rgba(255,255,255, .7);
        font-weight: bold;
        margin: 0 auto 31rem;
        display: block;
        -webkit-box-shadow: 3px 4px 0 1px rgba(0, 0, 0, 0.11);
        -moz-box-shadow: 3px 4px 0 1px rgba(0, 0, 0, 0.11);
        box-shadow: 3px 4px 0 1px rgba(0, 0, 0, 0.11);
        //box-shadow: 0 0 3px 0 #ccc;
        border-radius: 3px;
        @media (min-width: $sm) {
          margin: 0 auto;
        }
        @media (min-width: $md) {
          width: 36rem;
          font-size: 3.2rem;
        }
        &:hover{
          background: rgba(255,255,255, .8);
        }
      }

    }
    .wrapp_logo{
      position: absolute;
      bottom: 0;
      left: 2rem;
      display: block;
      @media (min-width: $sm) {
        left: 5rem;
      }
      @media (min-width: $md) {
        display: flex;
      }
      @media (min-width: $md + 1) {
        bottom: 5rem;
      }
      @media (min-width: $xl) {
        bottom: 10rem;
        left: 20rem;
      }
      .slide__row{
        text-transform: uppercase;
        color: #fff;
        font: 400 1.5rem 'Open Sans', sans-serif;
        a{
          font: 1.5rem 'Open Sans', sans-serif;
          color: #fff;
        }
        .slide__phone {
          font: 2.5rem 'Open Sans', sans-serif;
          @media (min-width: $xl) {
            font-size: 4.2rem;
          }
        }
        @media (min-width: $md) {
          font-size: 1.8rem;
          a {
            font-size: 2.5rem;
          }
        }
        @media (min-width: $xl) {
          margin-right: 60px;
          a{
            line-height: 4.8rem;
          }
        }
      }
    }
  }

  &__review{
    .slide__layout{
      width: 100%;
      padding: 0;
      @media (min-width: $md) and (orientation: landscape) {
        width: 80%;
        margin: 0 auto;
      }
    }
    .slide__label{
      position: absolute;
      text-align: right;
      display: block;
      @include media(phone) {
        display: none;
      }
      @media (min-width: $md) {
        top: 15rem;
        right: 6rem;
      }
      /*@media (min-width: $lg) {
        top: 10rem;
        right: 0;
      }*/
      h2{
        color: #009640;
        font-size: 20px;
      }
    }
    .fp-slidesNav.top{
      display: none;
    }
    .review-slider__slide{
      align-items: center;
    }
    .section__inner{
      margin: 0 auto;
      .slide__top_title {
        @media (min-width: $md) {
          padding: 1rem 5.8rem;
        }
        .slide__inner {
          @include media(tablet) {
            width: 65%;
          }
        }

      }
      .slide__container{
        padding: 0 10rem;
        @include media(phone){
          padding: 0 8rem;
        }
      }
    }
    .review-slider__containe{
      position: relative;
    }
    .fp-controlArrow{
      /*top: 50%;*/
      opacity: 1;
      &:before {
        border-color: #7fca9f;
        /*border-width: 0.4rem;*/
      }
      &:hover:before {
        border-color: #7fca9f;
      }
    }
  }
}

.user_content {
  z-index: 20;
  h2 {
    text-align: right;
    @include media(tablet) {
      @include font(6rem, 400, 6.2rem, OfficinaSansExtraBoldOSC);
    }
    @include font(2.4rem, 400, 2.6rem, OfficinaSansExtraBoldOSC);
    @media (min-width: $lg) {
      @include font(6rem, 400, 5.8rem, OfficinaSansExtraBoldOSC);
    }
  }
  h3 {
    @include font(1.8rem, 400, 2.7rem, OfficinaSansExtraBoldC);
    @include media(tablet) {
      @include font(3.6rem, 400, 4.2rem, OfficinaSansExtraBoldC);
    }
    @media (min-width: $lg) {
      @include font(4.4rem, 400, 4.8rem, OfficinaSansExtraBoldC);
    }
  }
  p {
    @include font(1.8rem, 400, 2.3rem, OfficinaSansMediumC);
  }
}

.color-blue {
  color: #8cd1f9;
}

.img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

// Подсветка под надписями
.brighten {
  position: absolute;
  z-index: 11;
  background: rgba(#fff, .46);
  box-shadow: 0 0 200px 100px rgba(#fff, .5);
}

.onlinebees {
  position: absolute;
  z-index: 2000;
  right: 4.3rem;
  bottom: 1.5rem;
  display: flex;
  text-align: right;
  text-decoration: none;
  color: yellow;
  align-items: center;
  @include font(1.4rem, 400, 1.4rem);
  justify-content: flex-end;
  &:hover {
    text-decoration: underline;
  }
}

// Саша А. добавлял
.slide__round{
  overflow:unset;
  position:relative;
}
img.slide__flag_planed{
  left:-12px;
  position:absolute;
  top:48px;
  max-width:none;
}

#block_reservation{

}
.section__logo {
  position: relative;
}
#want_here_live {
  background: url("/images/bg_wonthrerelive.jpg") no-repeat center right;
  border-radius: 10px;
  min-width: 26rem;
  width: 100%;
  color: #35aa46;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  @media (min-width: $sm) and (orientation: landscape) {
    top: -130px;
    z-index: 1;
  }
  @media (min-width: $md){
    width: 46rem;
    top: -100px;
    left: 50%;
    margin-left: -50%;
  }
  @media (min-width: $lg){
    margin-left: -40%;
  }
  @media (min-width: $xl){
    margin-left: -23rem;
  }
  h2{
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  input[type='text'], input[type='tel']{
    color: #35aa46;
    background: rgba(53,170,70,.4);
    font-size: 25px;
    border: none;
    margin: 0 auto 20px;
    width: 100%;
    line-height: 2;
    padding: 0 10px;
    display: block;
    &::placeholder{
      color: #35aa46;
    }
  }
  button {
    color: #fff;
    background: #35aa46;
    border: none;
    font-size: 30px;
    line-height: 60px;
    display: block;
    margin: 0 auto;
    width: 50%;
    min-width: 210px;
  }

}

.ui-state-error{
  outline: 1px solid red;
}
.ui-state-highlight{
  color: red;}

ul.social_ico{
  li{
    list-style:none;
    display: inline-block;
    &>a{
      display: inline-block;
    }
    .fb_ico{
      @include sprite($facebook-logo);
    }
    .vk_ico{
      @include sprite($vk_social);
    }
    .ins_ico{
      @include sprite($instagram-logo);
    }
  }
}