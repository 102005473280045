// /src/scss/_fonts.scss

@font-face {
  font-family: 'OfficinaSansBookC';
  src: url('/fonts/ITCOfficinaSans/OfficinaSansBookC/OfficinaSansBookC.eot');
  src:  local('☺'),
        url('/fonts/ITCOfficinaSans/OfficinaSansBookC/OfficinaSansBookC.woff') format('woff'),
        url('/fonts/ITCOfficinaSans/OfficinaSansBookC/OfficinaSansBookC.ttf') format('truetype'),
        url('/fonts/ITCOfficinaSans/OfficinaSansBookC/OfficinaSansBookC.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OfficinaSansBookCItalic';
  src: url('/fonts/ITCOfficinaSans/OfficinaSansBookCItalic/OfficinaSansBookCItalic.eot');
  src: local('☺'),
        url('/fonts/ITCOfficinaSans/OfficinaSansBookCItalic/OfficinaSansBookCItalic.woff') format('woff'),
        url('/fonts/ITCOfficinaSans/OfficinaSansBookCItalic/OfficinaSansBookCItalic.ttf') format('truetype'),
        url('/fonts/ITCOfficinaSans/OfficinaSansBookCItalic/OfficinaSansBookCItalic.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OfficinaSansMediumC';
  src:  url('/fonts/ITCOfficinaSans/OfficinaSansMediumC/OfficinaSansMediumC.eot');
  src:  local('☺'),
        url('/fonts/ITCOfficinaSans/OfficinaSansMediumC/OfficinaSansMediumC.woff') format('woff'),
        url('/fonts/ITCOfficinaSans/OfficinaSansMediumC/OfficinaSansMediumC.ttf') format('truetype'),
        url('/fonts/ITCOfficinaSans/OfficinaSansMediumC/OfficinaSansMediumC.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OfficinaSansExtraBoldC';
  src: url('/fonts/ITCOfficinaSans/OfficinaSansExtraBoldC/OfficinaSansExtraBoldC.eot');
  src: local('☺'),
      url('/fonts/ITCOfficinaSans/OfficinaSansExtraBoldC/OfficinaSansExtraBoldC.woff') format('woff'),
      url('/fonts/ITCOfficinaSans/OfficinaSansExtraBoldC/OfficinaSansExtraBoldC.ttf') format('truetype'),
      url('/fonts/ITCOfficinaSans/OfficinaSansExtraBoldC/OfficinaSansExtraBoldC.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OfficinaSansExtraBoldOSC';
  src: url('/fonts/ITCOfficinaSans/OfficinaSansExtraBoldOSC/OfficinaSansExtraBoldOSC.eot');
  src: local('☺'),
      url('/fonts/ITCOfficinaSans/OfficinaSansExtraBoldOSC/OfficinaSansExtraBoldOSC.woff') format('woff'),
      url('/fonts/ITCOfficinaSans/OfficinaSansExtraBoldOSC/OfficinaSansExtraBoldOSC.ttf') format('truetype'),
      url('/fonts/ITCOfficinaSans/OfficinaSansExtraBoldOSC/OfficinaSansExtraBoldOSC.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

