// нормализация стилей страницы     https://github.com/necolas/normalize.css
@import 'base/normalize';

// библиотека миксинов, для создания гибкой системы сеток https://github.com/oddbird/susy/
// почитать на русском здесь    http://zencoder.ru/susy/brief-tutorial-susy-2/
//                   и здесь    http://zencoder.ru/css/static-grids-susy/
@import "susy/sass/susy";
// инициализация susy http://susydocs.oddbird.net/en/latest/settings/
@import "vendor/susy-settings";

@import 'helpers/vars';         // переменные проекта
@import 'helpers/mixins';       // миксины проекта
@import 'base/fonts';           // шрифты

@import 'base/reset';           // сброс и определение глобальных стилей
@import 'base/typography';      // типографика проекта
@import 'icons';                // всё, что касается спрайта/иконок, SGV
@import 'sprite';                // всё, что касается спрайта/иконок, SGV

@import 'common';               // Общие стили
@import 'main';                 // Основные стили
@import "vendor/swiper";        // настройка слайдера swiper http://idangero.us/

// @import 'user-content';        // Файл стилей пользователей (добавление через админку)

// Стили подключенных библиотек
@import 'vendor/fullpage';      // https://github.com/alvarotrigo/fullPage.js