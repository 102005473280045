// /src/scss/см. _typography.scss

// // @extend %h1;        // см. _typography.scss
// h1,
// %h1 {
//  text-transform: uppercase;

//  @include font(3.6rem, 500, 4.6rem);
// }

// // @extend %h2;        // см. _typography.scss
// h2,
// %h2 {
//  text-transform: uppercase;

//  @include font(2.4rem, 500, 4.6rem);
// }

// // @extend %h3;        // см. _typography.scss
// h3,
// %h3 {
//  text-transform: uppercase;

//  @include font(2.4rem, 800, 2.1rem);
// }

// // @extend %h4;        // см. _typography.scss
// h4,
// %h4 {
//  @include font(2.4rem, 800, 2.1rem);
// }

// // @extend %h5;        // см. _typography.scss
// h5,
// %h5 {
//  @include font(1.6rem, 700, 2.6rem);
// }

// // @extend %page__subtitle; // см. _typography.scss
// %page__subtitle {
//  @include font(3rem, 600, 3.6rem);
// }

// // @extend %p;        // см. _typography.scss
// p,
// %p {
//  @include font(1.8rem, 300, 2.6rem);
// }


// i
// ,
// em{
//   font-weight: 700;
//   font-style: italic;
// }

// b{
//   font-weight: 600;
// }

// strong{
//   font-weight: 900;
// }