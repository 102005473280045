@import 'sprite';

.icon {
  display: inline-block;
  background-image: url(/images/sprite.png);
}

.icon-mouse,
.icon-arrow {
  position: absolute;
  left: 0;
  z-index: 9999;
}

.icon-mouse {
  top: 0;
}

.icon-arrow {
  bottom: 0;
}

.onlinebees-svg {
  width: 3rem;
  height: 3.5rem;
  margin-left: 1.3rem;
}

.onlinebees-hexagon {
  fill: yellow;
}

.onlinebees:hover .onlinebees-svg .onlinebees-hexagon {
  fill: red;
}

