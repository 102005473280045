// /src/scss/helpers/_mixins.scss

// ############################# mixin's #############################

// @include min-width; // задаёт минимальную ширину сайта, если сайт не адаптивный см. /helpers/_mixins.scss
//@mixin min-width($adapt: #{$adaptive}) {
//  @if ($adapt == "false") {
//    min-width: $site-width;
//  } @else if ($adapt == "true") {
//    min-width: 0;
//  }
//}

// @include font($font-size, $font-weight: 400, $line-height: 1.5, $font-family: $font); // Font берется из _vars.scss
@mixin font($font-size, $font-weight: 400, $line-height: 1.5, $font-family: $font) {
  font: $font-weight #{$font-size} / #{$line-height} $font-family;
}

// возможные значения - phone, tablet, laptop, desktop, wide. см. _mixins.scss
// @include media() {}
@mixin media($media: desktop) {
  @if $media == phone {
    /*@media (min-width: $sm) and (max-width: $md - 1) {*/
    @media (max-width: $md - 1) {
      @content;
    }
  }
  @else if $media == tablet {
    @media (min-width: $md) and (max-width: $lg - 1) {
      @content;
    }
  }
  @else if $media == laptop {
    @media (min-width: $lg) and (max-width: $xl - 1) {
      @content;
    }
  }
  @else if $media == desktop {
    @media (min-width: $xl) and (max-width: $xxl - 1) {
      @content;
    }
  }
  @else if $media == wide {
    @media (min-width: $xxl) {
      @content;
    }
  }
}
