// /src/scss/_reset.scss
html,
body {
  font-family: $font-family;

  margin: 0;
  padding: 0;

  color: $black;
  ;
}

html {
  font-size: 62.5%; // 62.5% of the default of 16px, which equates to 10px

  box-sizing: border-box;
  // height: 100vh; // http://tutorialzine.com/2016/03/quick-tip-the-best-way-to-make-sticky-footers/

  background-color: transparent;
}

body {
  background-color: white;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

// http://nicolasgallagher.com/micro-clearfix-hack/
.clearfix:before,
.clearfix:after {
  display: table;
  /* 2 */

  content: " ";
  /* 1 */
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
}
